<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <img class="main-logo" :src="appLogoImage">
                <h2 class="brand-text text-primary ml-1">
                    Çevre Şikayet
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="4"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >
                    <b-card-title
                        class="mb-1 font-weight-bold"
                        title-tag="h2"
                    >
                        Çevre Şikayet'e Hoş Geldiniz! 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Lütfen hesabınızda oturum açın ve çalışmaya başlayın
                    </b-card-text>

                    <!--          <b-alert-->
                    <!--            variant="primary"-->
                    <!--            show-->
                    <!--          >-->
                    <!--            <div class="alert-body font-small-2">-->
                    <!--              <p>-->
                    <!--                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>-->
                    <!--              </p>-->
                    <!--              <p>-->
                    <!--                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>-->
                    <!--              </p>-->
                    <!--            </div>-->
                    <!--            <feather-icon-->
                    <!--              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"-->
                    <!--              icon="HelpCircleIcon"-->
                    <!--              size="18"-->
                    <!--              class="position-absolute"-->
                    <!--              style="top: 10; right: 10;"-->
                    <!--            />-->
                    <!--          </b-alert>-->

                    <!-- form -->
                    <validation-observer
                        ref="loginForm"
                        #default="{invalid}"
                    >
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent="login"
                        >
                            <!-- email -->
                            <b-form-group
                                label="E-posta"
                                label-for="login-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    vid="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="userEmail"
                                        :state="errors.length > 0 ? false:null"
                                        name="login-email"
                                        placeholder="john@example.com"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">Parola</label>
                                    <b-link :to="{name:'auth-forgot-password'}">
                                        <small>Parolanızı mı unuttunuz?</small>
                                    </b-link>
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    vid="password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="errors.length > 0 ? false:null"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="Parola"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="status"
                                    name="checkbox-1"
                                >
                                    Beni Hatırla
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                :disabled="invalid"
                            >
                                Oturum aç
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <!--          <b-card-text class="text-center mt-2">-->
                    <!--            <span>Platformumuzda yeni misiniz? </span>-->
                    <!--            <b-link :to="{name:'auth-register'}">-->
                    <!--              <span>&nbsp;Bir hesap oluşturun</span>-->
                    <!--            </b-link>-->
                    <!--          </b-card-text>-->

                    <!-- divider -->
                    <!--          <div class="divider my-2">-->
                    <!--            <div class="divider-text">-->
                    <!--              or-->
                    <!--            </div>-->
                    <!--          </div>-->

                    <!-- social buttons -->
                    <!--          <div class="auth-footer-btn d-flex justify-content-center">-->
                    <!--            <b-button-->
                    <!--              variant="facebook"-->
                    <!--              href="javascript:void(0)"-->
                    <!--            >-->
                    <!--              <feather-icon icon="FacebookIcon" />-->
                    <!--            </b-button>-->
                    <!--            <b-button-->
                    <!--              variant="twitter"-->
                    <!--              href="javascript:void(0)"-->
                    <!--            >-->
                    <!--              <feather-icon icon="TwitterIcon" />-->
                    <!--            </b-button>-->
                    <!--            <b-button-->
                    <!--              variant="google"-->
                    <!--              href="javascript:void(0)"-->
                    <!--            >-->
                    <!--              <feather-icon icon="MailIcon" />-->
                    <!--            </b-button>-->
                    <!--            <b-button-->
                    <!--              variant="github"-->
                    <!--              href="javascript:void(0)"-->
                    <!--            >-->
                    <!--              <feather-icon icon="GithubIcon" />-->
                    <!--            </b-button>-->
                    <!--          </div>-->
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {required, email} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
import {getAbilityForRole, getHomeRouteForLoggedInUser} from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {$themeConfig} from "@themeConfig";

export default {
    directives: {
        'b-tooltip': VBTooltip,
    },
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        // eslint-disable-next-line vue/no-unused-components
        BAlert,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            password: '123456789',
            userEmail: 'admin@test.com',
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            appLogoImage: $themeConfig.app.appLogoImage,

            // validation rules
            required,
            email,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    methods: {
        login() {
            this.$refs.loginForm.validate().then(success => {
                if (success) {
                    this.$http.post('/admin/login', {
                        email: this.userEmail,
                        password: this.password,
                        remember_me: this.status,
                    }).then(response => {
                        // debugger;
                        //console.log(response);
                        let user = response.data.user
                        user.ability = getAbilityForRole(user.role);
                        user.fullName = user.first_name + " " + user.last_name;
                        user.avatar = user.full_path;
                        useJwt.setToken(response.data.access_token)
                        useJwt.setRefreshToken(response.data.access_token)
                        localStorage.setItem('userData', JSON.stringify(user))
                        this.$ability.update(getAbilityForRole(user.role))
                        this.$router.replace(getHomeRouteForLoggedInUser(user.role))
                            .then(() => {
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: `Hoşgeldin ${user.first_name} ${user.last_name}`,
                                        icon: 'CoffeeIcon',
                                        variant: 'success',
                                        // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                                        text: `Başarıyla ${user.role} olarak giriş yaptınız.`,
                                    },
                                })
                            })
                    }).catch(err => {
                        if (err.response === undefined) {
                            this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: this.$t('Error'),
                                        icon: 'InfoIcon',
                                        text: err.message,
                                        variant: 'danger',
                                    },
                                },
                                {
                                    position: 'bottom-right',
                                });
                            return
                        }
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: this.$t('Error'),
                                    icon: 'InfoIcon',
                                    text: err.response.data.message,
                                    variant: 'danger',
                                },
                            },
                            {
                                position: 'bottom-right',
                            })
                    })
                    // useJwt.login({
                    //     email: this.userEmail,
                    //     password: this.password,
                    // })
                    //     .then(response => {
                    //         // debugger
                    //         const {userData} = response.data
                    //         useJwt.setToken(response.data.accessToken)
                    //         useJwt.setRefreshToken(response.data.refreshToken)
                    //         localStorage.setItem('userData', JSON.stringify(userData))
                    //         this.$ability.update(userData.ability)
                    //
                    //         // ? This is just for demo purpose as well.
                    //         // ? Because we are showing eCommerce app's cart items count in navbar
                    //         this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
                    //
                    //         // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                    //         this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                    //             .then(() => {
                    //                 this.$toast({
                    //                     component: ToastificationContent,
                    //                     position: 'top-right',
                    //                     props: {
                    //                         title: `Hoşgeldin ${userData.fullName || userData.username}`,
                    //                         icon: 'CoffeeIcon',
                    //                         variant: 'success',
                    //                         // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    //                         text: `Başarıyla ${userData.role} olarak giriş yaptınız.`,
                    //                     },
                    //                 })
                    //             })
                    //     })
                    //     .catch(error => {
                    //         this.$refs.loginForm.setErrors(error.response.data.error)
                    //     })
                }
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.brand-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    .brand-text{
        font-size: 2rem;
    }
    .main-logo{
        width: 3em;
        object-fit: contain;
    }
}

</style>
